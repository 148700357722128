import React from "react";

export default function LoggedIn() {
  return (
    <div>
      <h1>Please complete your profile in the profile section</h1>
      <h2>Please use this application in desktop mode</h2>
    </div>
  );
}
