import React, { useContext, useEffect } from "react";
import DataProvide from "../Context/DataContext";
import Dashboard from "../Components/Dashboard";
import { AuthContext } from "../Context/AuthContext";
import { SnackbarContext } from "../Context/SnackbarContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function DashboardRoutes() {
  // checking if profile completed, give reminder if not on every route change
  const { user } = useContext(AuthContext);
  const { callSnackbar } = useContext(SnackbarContext);
  const location = useLocation();

  useEffect(() => {
    user &&
      !user.userData.candidateDetails.profileComplete &&
      callSnackbar(
        true,
        "Please complete your profile in the profile section!",
        "error"
      );
  }, [location]);

  return (
    <>
      {/* DataProvide exposes, global data like title, which will be used by different components inside our app, like Header.js  */}
      <DataProvide>
        <Dashboard />
      </DataProvide>
    </>
  );
}
