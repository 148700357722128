import React from "react";
import TrainingCards from "./loggedincomponents/TrainingCards";

function Training() {
  return (
    <div>
      <TrainingCards />
    </div>
  );
}

export default Training;
